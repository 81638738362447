
















































































































import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import * as clipboardy from "clipboardy";
import TokensForm from "./../../components/tokens/TokensForm.vue";
import TokenEdit from "./../../components/tokens/TokenEdit.vue";
import { PropType } from "vue";
import { TokenState } from "../../store/tokens";
import { SpeakerInfo } from "../../../../es-domain/lib/models/GradingResponse";

export default Vue.extend({
  components: {
    TokensForm,
    TokenEdit
  },
  props: {
    tokenState: {
      type: String as PropType<TokenState>,
      required: true
    }
  },
  data: () => ({
    search: ""
  }),
  computed: {
    ...mapGetters("tokens", [
      "busy",
      "loadingText",
      "noDataText",
      "headers",
      "items",
      "tokenName"
    ])
  },
  methods: {
    ...mapActions("tokens", [
      "removeToken",
      "loadItems",
      "setTokenState",
      "clearTokenState"
    ]),
    ...mapActions("snackbar", ["snack"]),
    ...mapMutations("tokens", ["setItems"]),
    refresh() {
      this.loadItems();
    },
    async removeWithConfirm(code) {
      // Todo: Make this a modal dialog
      if (
        confirm(
          `⚠️ Please confirm\n\nThis will permanently remove the code: \n\n${code}\n\nAre you sure you want to remove it?`
        )
      ) {
        await this.removeToken({ code });
      }
    },
    async copyToClipboard(code) {
      await clipboardy.write(code);
      this.snack({
        mode: "success",
        message: `✅ Copied to clipboard: <strong class="px-4">${code}</strong>`
      });
    },
    async editToken(id) {
      switch (this.tokenState) {
        case TokenState.ConnectCode:
          this.$router.push({ name: "ConnectCodesEdit", params: { uuid: id } });
          break;
        case TokenState.CertificateCredit:
          this.$router.push({
            name: "CertificateCreditsEdit",
            params: { uuid: id }
          });
          break;
        case TokenState.CertificateVoucher:
          this.$router.push({
            name: "CertificateVouchersEdit",
            params: { uuid: id }
          });
          break;
      }
    }
  },
  watch: {
    search(value) {
      if (this.$route.query.search === value) return;

      const tokenSlug = this.$route.path.split("/")[1];
      if (!value) {
        this.$router.push(`/${tokenSlug}`);
      } else {
        this.$router.push(`/${tokenSlug}?search=${value}`);
      }
    },
    $route: {
      handler() {
        this.search = this.$route.query.search as string;
      },
      immediate: true
    }
  },
  beforeMount() {
    this.setTokenState(this.tokenState);
    this.setItems([]);
    this.loadItems();
  },
  beforeDestroy() {
    this.clearTokenState();
  }
});
