<template>
  <img :src="url" class="async image carousel-thumbnail" @click="$emit('click')" />
</template>
<script>
import { apiClient } from "@/services/api"

export default {
  name: 'ProctoringImages',
  props: ['href'],
  emits: ['click'],
  data() {
    return {
      url: null
    }
  },
  mounted() {
    console.log(this.href)
    apiClient.get(this.href, { responseType: 'blob' }).then(({ data }) => {
      // data is already a blob
      this.url = URL.createObjectURL(data)
    }).catch(error => {
      console.error(error)
    })
  }
}
</script>
<style lang="scss">
img.async {
  width: 200px;
  height: 150px;
  object-fit: contain;
  margin-right: 1em;
}
</style>